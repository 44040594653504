<template>
  <div class="frequency-form-items-container">
    <!-- 总请求上限（天） -->
    <a-form-model-item prop="requestFreq">
      <m-tips
        slot="label"
        :content="`设置该广告源请求次数上限/天。不填，则表示不限制；`"
        :title="'总请求上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.requestFreq"
        @change="handleInputProp($event, 'requestFreq')"
      />
    </a-form-model-item>
    <!-- 单设备请求上限（天） -->
    <a-form-model-item prop="deviceRequestFreq">
      <m-tips
        slot="label"
        :content="`设置单个用户在该广告源请求次数上限/天。不填，则表示不限制；`"
        :title="'单设备请求上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.deviceRequestFreq"
        @change="handleInputProp($event, 'deviceRequestFreq')"
      />
    </a-form-model-item>
    <!-- 单设备请求间隔（秒） -->
    <a-form-model-item prop="deviceRequestInterval">
      <m-tips
        slot="label"
        :content="`设置本次请求到下一次请求之间的时间间隔/秒。不填，则表示不限`"
        :title="'单设备请求间隔（秒）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.deviceRequestInterval"
        @change="handleInputProp($event, 'deviceRequestInterval')"
      />
    </a-form-model-item>
    <!-- 总展示上限（天） -->
    <a-form-model-item prop="impressFreqDay">
      <m-tips
        slot="label"
        :content="`设置该广告源展示次数上限/天。不填，则表示不限制；`"
        :title="'总展示上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.impressFreqDay"
        @change="handleInputProp($event, 'impressFreqDay')"
      />
    </a-form-model-item>
    <!-- 总点击上限（天） -->
    <a-form-model-item prop="clickFreqDay">
      <m-tips
        slot="label"
        :content="`设置该广告源点击次数上限/天。不填，则表示不限制；`"
        :title="'总点击上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.clickFreqDay"
        @change="handleInputProp($event, 'clickFreqDay')"
      />
    </a-form-model-item>
    <!-- 单设备展示上限（天） -->
    <a-form-model-item prop="deviceImpressFreqDay">
      <m-tips
        slot="label"
        :content="`设置该广告源在同一设备上展示次数上限/天。不填，则表示不限制；`"
        :title="'单设备展示上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.deviceImpressFreqDay"
        @change="handleInputProp($event, 'deviceImpressFreqDay')"
      />
    </a-form-model-item>
    <!-- 单设备点击上限（天） -->
    <a-form-model-item prop="deviceClickFreqDay">
      <m-tips
        slot="label"
        :content="`设置该广告源在同一设备上点击次数上限/天。不填，则表示不限制；`"
        :title="'单设备点击上限（天）'"
        :width="150"
      ></m-tips>
      <a-input
        style="width: 300px"
        placeholder="不限"
        v-model.trim="formquery.deviceClickFreqDay"
        @change="handleInputProp($event, 'deviceClickFreqDay')"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
export default {
  name: 'FormatFormItems',
  mixins: [mixDate],
  data () {
    return {
    }
  },
  created () {
    this.$set(this.formquery, 'requestFreq', this.value.requestFreq || undefined)
    this.$set(this.formquery, 'deviceRequestFreq', this.value.deviceRequestFreq || undefined)
    this.$set(this.formquery, 'deviceRequestInterval', this.value.deviceRequestInterval || undefined)
    this.$set(this.formquery, 'impressFreqDay', this.value.impressFreqDay || undefined)
    this.$set(this.formquery, 'clickFreqDay', this.value.clickFreqDay || undefined)
    this.$set(this.formquery, 'deviceImpressFreqDay', this.value.deviceImpressFreqDay || undefined)
    this.$set(this.formquery, 'deviceClickFreqDay', this.value.deviceClickFreqDay || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleInputProp (e, prop) {
      if (+e.target.value < 1) {
        this.formquery[prop] = undefined
      } else if (+e.target.value >= 1) {
        this.formquery[prop] = Math.ceil(+e.target.value)
      } else {
        this.formquery[prop] = undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
// .frequency-form-items-container{
//   border: 1px solid;
// }
</style>
